import React, { useState } from "react"
import Layout from "../components/layout"
import {
  MdLocationOn,
  MdCall,
  MdEmail,
  MdCheckCircle,
  MdSend,
  MdDone,
  MdError,
} from "react-icons/md"
import Seo from "../components/seo"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const SubmitState = {
  NORMAL: {
    className: "is-link",
    btnText: "Send",
    icon: <MdSend />,
  },
  SUCCESS: {
    className: "is-success",
    btnText: "Send Success",
    icon: <MdDone />,
  },
  FAILURE: {
    className: "is-danger",
    btnText: "Send Error",
    icon: <MdError />,
  },
}

const ContactForm = () => {
  const [form, setFormData] = useState({})
  const [submitState, setSubmitState] = useState(SubmitState.NORMAL)

  const setFormValue = e => {
    e.persist()
    setFormData(pv => ({ ...pv, [e.target.name]: e.target.value }))
  }

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...form,
      }),
    })
      .then(result => {
        if (result.ok) {
          setSubmitState(SubmitState.SUCCESS)
          setFormData({})
        } else {
          setSubmitState(SubmitState.FAILURE)
        }
      })
      .catch(error => setSubmitState(SubmitState.FAILURE))
  }

  return (
    <div
      className="box has-text-left is-justify-content-space-evenly is-flex is-flex-direction-column"
      style={{ height: "100%" }}
    >
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <div className="field">
          <label htmlFor="name" className="label">
            Name:
          </label>
          <div className="control">
            <input
              id="name"
              name="name"
              className="input"
              type="text"
              placeholder="Enter your name"
              value={form.name}
              onChange={setFormValue}
              required
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="email" className="label">
            Email:
          </label>
          <div className="control has-icons-left">
            <input
              id="email"
              name="email"
              className="input"
              type="email"
              placeholder="Enter your email"
              value={form.email}
              onChange={setFormValue}
              required
            />
            <span className="icon is-small is-left">
              <MdEmail />
            </span>
          </div>
        </div>

        <div className="field">
          <label htmlFor="message" className="label">
            Message:
          </label>
          <div className="control">
            <textarea
              id="message"
              name="message"
              className="textarea"
              placeholder="Enter your message here..."
              spellCheck="false"
              value={form.message}
              onChange={setFormValue}
              required
            ></textarea>
          </div>
        </div>

        <div className="field is-grouped has-text-centered">
          <div className="control">
            <button className={`button ${submitState.className}`}>
              <span className="icon">{submitState.icon}</span>
              <span>{submitState.btnText}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

function ContactCard() {
  return (
    <div className="is-flex is-flex-direction-column">
      <div className="block card">
        <div className="card-content is-flex is-flex-direction-column is-align-items-center">
          <MdLocationOn title="Current Location" size="2rem" />
          Bangalore
        </div>
      </div>
      <div className="block card">
        <a href="tel:+917905485167">
          <div className="card-content has-text-grey-dark is-flex is-flex-direction-column is-align-items-center">
            <MdCall title="Phone Number" size="2rem" />
            +91 7905<span className="is-hidden"> 548asd46</span>485167
          </div>
        </a>
      </div>
      <div className="block card">
        <a href="mailto:shi2rsingh@gmail.com">
          <div className="card-content has-text-grey-dark is-flex is-flex-direction-column is-align-items-center">
            <MdEmail title="E-Mail ID" size="2rem" /> shi2rsingh@g
            <span className="is-hidden"> 548asd46</span>mail.com
          </div>
        </a>
      </div>
      <div className="block card">
        <div className="card-content is-flex is-flex-direction-column is-align-items-center">
          <MdCheckCircle title="Yes" size="2rem" />
          Freelance Available
        </div>
      </div>
    </div>
  )
}

const Contact = () => {
  return (
    <Layout>
      <Seo title="Shishir Singh Contact" />
      <div className="hero is-fullheight-with-navbar">
        <div id="contact" className="section">
          <div className="section-heading has-text-centered">
              <h3 className="title is-2">Contact</h3>
              <h4 className="subtitle is-5">Get in touch</h4>
            <div className="container">
              <div className="columns">
                <div className="column is-one-third">
                  <ContactCard></ContactCard>
                </div>
                <div className="column is-two-thirds">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
